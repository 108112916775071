import Intro from '../components/processo-seletivo/Intro';
// import Team from '../components/processo-seletivo/Team';
import Summary from '../components/processo-seletivo/Summary';


export default function ProcessoSeletivo() {

  return (
    <>
      <Intro />
      <Summary />
      {/* <Team /> */}
    </>
  );
}